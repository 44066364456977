import { React, Component } from 'libraries';
import { FooterCopyright, FooterBy } from 'components/atoms';
import FooterWidget from '../FooterWidget';

class BaseFooter extends Component {
  render() {
    return (
      <footer id="footer">
        <FooterWidget />
        <div className="footer-bottom">
          <div className="row">
            <div className="large-6 columns">
              <FooterCopyright />
            </div>
            <div className="large-3 large-offset-3 columns">
              <FooterBy />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default BaseFooter;
