import { React, Component, PropTypes } from 'libraries';

class Tab extends Component {
  static propTypes = {
    children: PropTypes.any
  };

  render() {
    const { children } = this.props;
    return <div className="tabs-holder">{children}</div>;
  }
}

export default Tab;
