import { React, PropTypes, _ } from 'libraries';

const SocialFooter = ({ data }) => (
  <div className="widget widget_social clearfix">
    <h3 className="widget-title">Sosial Media</h3>
    <ul className="social-icons">
      {data && _.isArray(data) && !_.isEmpty(data)
        ? data.map((val, i) => (
            <li key={i} className={val.type}>
              <a href={val.url} target="_blank" rel="noopener noreferrer">
                {val.name}
              </a>
            </li>
          ))
        : null}
    </ul>
  </div>
);

SocialFooter.propTypes = {
  data: PropTypes.array
};

SocialFooter.defaultProps = {
  data: []
};

export default SocialFooter;
