import { React, PropTypes } from 'libraries';
import { connect } from 'react-redux';
import Website from 'website';

const AuthorBox = ({ title, description, socialData }) => (
  <div className="author-holder clearfix">
    {/* <div className="author-thumb">
      <div className="avatar">
        <img
          alt=""
          src="http://1.gravatar.com/avatar/f7b924620556687f7c795aab0c319b60?s=100&amp;d=&amp;r=G"
          className="avatar avatar-100 photo"
          height="100"
          width="100"
        />
      </div>
    </div> */}
    <div className="author-about">
      <h4 className="author-title">{title}</h4>
      <p>{description}</p>
      <div className="author-contacts">
        <ul className="social-icons">
          {socialData &&
            socialData.map((val, i) => (
              <li key={i} className={val.type}>
                <a
                  target="_blank"
                  title={val.name}
                  rel="noopener noreferrer"
                  href={val.url}
                >
                  {val.name}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  </div>
);

AuthorBox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  socialData: PropTypes.array
};
AuthorBox.defaultProps = {
  title: Website.title,
  description: Website.description,
  socialData: []
};

const reduxState = state => ({
  socialData: state.social.data
});

export default connect(
  reduxState,
  null
)(AuthorBox);
