import { React, PropTypes, _ } from 'libraries';

const BannerHTML = ({ htmlContent }) => (
  <div className="m-banner-html__wrapper">
    {htmlContent && _.isString(htmlContent) ? (
      <div
        className="m-banner-html__content"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>
    ) : (
      <div className="m-banner-html__empty-state"></div>
    )}
  </div>
);

BannerHTML.propTypes = {
  htmlContent: PropTypes.string
};

BannerHTML.defaultProps = {
  htmlContent: ''
};

export default BannerHTML;
