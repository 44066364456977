import { React, PropTypes } from 'libraries';
import Website from 'website';

const FooterAddress = ({ data }) => (
  <div className="widget widget_social clearfix">
    <h3 className="widget-title">Alamat</h3>
    <p>{data.address}</p>
    <h3 className="widget-title">Telp/Fax</h3>
    <p>{data.phone}</p>
    <h3 className="widget-title">Email</h3>
    <p>{data.email}</p>
  </div>
);

FooterAddress.propTypes = {
  data: PropTypes.object
};

FooterAddress.defaultProps = {
  data: {
    address: Website.address,
    phone: Website.phone,
    email: Website.email
  }
};

export default FooterAddress;
