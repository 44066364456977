import { React, PropTypes, moment, _ } from 'libraries';
import { config } from 'config/api/url';
import { Tools } from 'utils';

const Headline = ({ data, showImage, showMeta }) => {
  const image = Tools.getImage(data);
  return (
    <React.Fragment>
      {showImage && (
        <div className="image-post item-overlay single-image-link">
          <img src={`${image}`} alt={data.title} />
        </div>
      )}
      <header className="entry-header">
        <h2 className="entry-title">{data.title}</h2>
        {showMeta && (
          <div className="entry-meta">
            {data.created_at && (
              <span className="posted-on">
                <a href="##">{moment(data.created_at).format('LLLL')}</a>
              </span>
            )}
            <span className="byline">
              <a href="##">{data && data.author ? data.author.name : null}</a>
            </span>

            {data && data.categories ? (
              <span className="tags-links">
                {data.categories.map((category, i) => (
                  <a href="##" rel="tag" key={i}>
                    {category.name}
                  </a>
                ))}
              </span>
            ) : null}
          </div>
        )}
      </header>
    </React.Fragment>
  );
};
Headline.propTypes = {
  data: PropTypes.object,
  showImage: PropTypes.bool,
  showMeta: PropTypes.bool
};

Headline.defaultProps = {
  showImage: true,
  showMeta: true
};

export default Headline;
