import { React, Component, PropTypes, Link, _ } from 'libraries';
import { config } from 'config/api/url';
import { API } from 'config';
import { PostCard, Skeleton } from 'components/molecules';

class RelatedArticles extends Component {
  static propTypes = {
    type: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      isError: false,
      isEmpty: false
    };
  }

  componentDidMount() {
    this.getPosts();
  }

  componentDidUpdate(props) {
    const { type } = this.props;
    if (props.type !== type) {
      this.getPosts();
    }
  }

  _postUrl = (type, url) => `/${type}/${url}`;

  getPosts = () => {
    const { type } = this.props;

    if (!type) return null;

    this.setState(
      {
        isLoading: true
      },
      () => {
        const payload = {
          params: { type }
        };
        API.getRelatedPost(payload)
          .then(res => {
            if (res.status) {
              const { data } = res;
              if (_.isEmpty(data)) {
                return this.setState({
                  isLoading: false,
                  isEmpty: true,
                  isError: false
                });
              }

              return this.setState({
                isLoading: false,
                isEmpty: false,
                isError: false,
                data
              });
            }
          })
          .catch(err => {
            console.log('TCL: RelatedArticles -> getPosts -> err', err);
            this.setState({
              isLoading: false,
              isEmpty: false,
              isError: true
            });
          });
      }
    );
  };

  render() {
    const { data, isLoading, isError, isEmpty } = this.state;
    return (
      <div className="related-article-area">
        <h2 className="section-title">Postingan Terkait</h2>
        <div className="row row-flex">
          {isLoading &&
            [1, 2, 3].map(val => (
              <div key={val} className="columns small-12 medium-6 large-4">
                <Skeleton
                  style={{
                    width: '100%',
                    paddingBottom: '62.25%',
                    marginBottom: 24
                  }}
                />
                <Skeleton
                  style={{
                    width: '60%',
                    paddingBottom: 20,
                    marginBottom: 14
                  }}
                />

                <Skeleton
                  style={{
                    width: '40%',
                    paddingBottom: 10
                  }}
                />
              </div>
            ))}
          {isError && (
            <div className="center">
              <p>
                Terjadi kesalahan! Periksa koneksi internet anda atau muat ulang
                halaman
              </p>
            </div>
          )}

          {isEmpty && (
            <div className="center">
              <p>Tidak ada postingan terkait</p>
            </div>
          )}
          {data &&
            data.map((post, i) => (
              <div key={i} className="columns small-12 medium-6 large-4">
                <PostCard
                  url={this._postUrl(post.type.slug, post.url)}
                  wrapperStyle={{
                    borderBottomWidth: 0
                  }}
                  showFooter={false}
                  showSnippet={false}
                  data={post}
                  key={i}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default RelatedArticles;
