import actionTypes from './const';

export const setBannerHeader = value => ({
  type: actionTypes.SET_BANNER_HEADER,
  value
});

export const setBannerSidebar = value => ({
  type: actionTypes.SET_BANNER_SIDEBAR,
  value
});

export const setBannerFooter = value => ({
  type: actionTypes.SET_BANNER_FOOTER,
  value
});
