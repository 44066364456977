import { React, PropTypes } from 'libraries';

const SearchNav = props => (
  <div className="search-form-nav">
    <form method="get" action="/search">
      <fieldset>
        <input
          placeholder="Search"
          type="text"
          name="keyword"
          autoComplete="off"
          defaultValue=""
          className="advanced_search"
        />
        <button type="submit" className="submit-search">
          Search
        </button>
      </fieldset>
    </form>
  </div>
);

export default SearchNav;
