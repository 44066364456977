import { React, PropTypes } from 'libraries';
import {
  FooterAddress,
  AboutBrand,
  SocialFooter,
  NavPages
} from 'components/molecules';
import { connect } from 'react-redux';

// const objectToArray = obj => {
//   return Object.keys(obj).map(key)
// }

const FooterWidget = ({ socialLink }) => (
  <div className="footer-top">
    <div className="row">
      <div className="large-4 columns">
        <AboutBrand />
        <SocialFooter data={socialLink} />
      </div>
      <div className="large-4 columns">
        <NavPages />
      </div>
      <div className="large-4 columns">
        <FooterAddress />
      </div>
    </div>
  </div>
);

FooterWidget.propTypes = {
  socialLink: PropTypes.any
};

const reduxState = state => ({
  socialLink: state.social.data
});

export default connect(
  reduxState,
  null
)(FooterWidget);
