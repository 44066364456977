import { React, Component, PropTypes, _ } from 'libraries';
import { CardRecentPost, Skeleton } from 'components/molecules';
import { API } from 'config';
import { Tools } from 'utils';

class PopularPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: []
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        API.getPopularPost().then(res => {
          if (res.status) {
            this.setState({
              data: res.data,
              isLoading: false
            });
          }
        });
      }
    );
  };

  render() {
    const { data, isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading &&
          [1, 2, 3, 4].map(val => (
            <Skeleton
              key={val}
              style={{
                width: '100%',
                paddingBottom: '100px',
                marginBottom: 14
              }}
            />
          ))}
        {data && !_.isEmpty(data)
          ? data.map((val, i) => (
              <CardRecentPost
                key={i}
                data={val}
                url={Tools.postUrl(val.type.slug, val.url)}
              />
            ))
          : null}
      </React.Fragment>
    );
  }
}

export default PopularPost;
