import { React, Component, Fragment, _, PropTypes } from 'libraries';
import { PostCard, Skeleton } from 'components';
import { BaseLayout } from 'layout';
import { API } from 'config';

class Search extends Component {
  static propTypes = {
    location: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEmpty: false,
      keywordTitle: '',
      posts: []
    };
  }

  componentDidMount() {
    document.title = 'Pencarian';
    this.getData();
  }

  componentDidUpdate(props) {
    const { location } = this.props;
    if (location.search !== props.location.search) {
      this.getData();
    }
  }

  postUrl = (type, url) => `/${type}/${url}`;

  getData = () => {
    const { location } = this.props;
    this.setState(
      {
        isLoading: true,
        isEmpty: false,
        posts: []
      },
      () => {
        if (location.search) {
          let keyword = location.search;
          keyword = keyword.substr(1);
          // eslint-disable-next-line prefer-destructuring
          keyword = keyword.split('=')[1];

          const keywordTitle = keyword.replace('+', ' ');
          document.title = `Pencarian : ${keyword.replace('+', ' ')}`;
          this.setState({ keywordTitle });

          if (keyword) {
            const payload = {
              params: {
                q: keyword
              }
            };
            API.searchPost(payload)
              .then(res => {
                if (res.status && _.isEmpty(res.data)) {
                  this.setState({
                    isEmpty: true,
                    isLoading: false
                  });
                }
                if (res.status && !_.isEmpty(res.data)) {
                  const posts = res.data;
                  this.setState({
                    posts,
                    isLoading: false,
                    isEmpty: false
                  });
                }
              })
              .catch(err => {
                this.setState({
                  isLoading: false,
                  isEmpty: true
                });
              });
          }
        }
      }
    );
  };

  render() {
    const { posts, isLoading, isEmpty, keywordTitle } = this.state;
    return (
      <BaseLayout showSidebar={false} {...this.props}>
        <section id="main">
          <div className="section padding-off columns medium-12 large-12 background-color-off">
            <div className="row">
              <div className="relative">
                <h2 className="section-title">Pencarian: {keywordTitle}</h2>
                <div className="row row-flex">
                  {!_.isEmpty(posts) && _.isArray(posts)
                    ? posts.map((data, i) => (
                        <div key={i} className="columns medium-6 large-4">
                          <PostCard
                            url={this.postUrl(data.type.slug, data.url)}
                            key={i}
                            data={data}
                          />
                        </div>
                      ))
                    : null}
                  {isEmpty && (
                    <div className="colums small-12 medium-12 large-12">
                      <div className="center">
                        Pencarian {keywordTitle} tidak ditemukan
                      </div>
                    </div>
                  )}
                  {isLoading &&
                    [1, 2, 3, 4, 5, 6].map(val => (
                      <div key={val} className="columns medium-6 large-4">
                        <Skeleton
                          style={{
                            width: '100%',
                            paddingBottom: '65.25%',
                            marginBottom: 24
                          }}
                        />
                        <Skeleton
                          style={{
                            width: '80%',
                            paddingBottom: 20,
                            marginBottom: 14
                          }}
                        />
                        <Skeleton
                          style={{
                            width: '40%',
                            paddingBottom: 20,
                            marginBottom: 14
                          }}
                        />
                        <Skeleton
                          style={{
                            width: '30%',
                            paddingBottom: 20,
                            marginBottom: 14
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default Search;
