import { React, PropTypes } from 'libraries';

const FooterCopyright = ({ text }) => (
  <div className="copyright">
    <span>{text}</span>
  </div>
);

FooterCopyright.propTypes = {
  text: PropTypes.string
};

FooterCopyright.defaultProps = {
  text: 'Copyright by LPQT Papua'
};

export default FooterCopyright;
