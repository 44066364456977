import actionTypes from './const';

const initialState = {
  facebook: '',
  twitter: '',
  instagram: '',
  youtube: '',
  whatsapp: '',
  website: '',
  data: []
};

const social = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SOCIAL_LINK_DATA:
      return {
        ...state,
        data: action.value
      };
    case actionTypes.SET_SOCIAL_LINK_FACEBOOK:
      return {
        ...state,
        facebook: action.value
      };
    case actionTypes.SET_SOCIAL_LINK_INSTAGRAM:
      return {
        ...state,
        instagram: action.value
      };
    case actionTypes.SET_SOCIAL_LINK_TWITTER:
      return {
        ...state,
        twitter: action.value
      };
    case actionTypes.SET_SOCIAL_LINK_YOUTUBE:
      return {
        ...state,
        youtube: action.value
      };
    case actionTypes.SET_SOCIAL_LINK_WHATSAPP:
      return {
        ...state,
        whatsapp: action.value
      };
    case actionTypes.SET_SOCIAL_LINK_WEBSITE:
      return {
        ...state,
        website: action.value
      };
    default:
      return state;
  }
};

export default social;
