import { React, Component, PropTypes } from 'libraries';
import { API } from 'config';
import { EventItem } from '../../atoms';

class FeaturedEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    API.getUpdatedPost().then(res => {
      const { data } = res;
      this.setState({ data });
    });
  };

  render() {
    const { data } = this.state;
    return (
      <div className="widget widget_upcoming_events">
        <h3 className="widget-title">Terupdate</h3>
        <ul>
          {data && data.map((post, i) => <EventItem key={i} data={post} />)}
        </ul>
      </div>
    );
  }
}

export default FeaturedEvents;
