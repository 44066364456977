import { React, Component, PropTypes } from 'libraries';
import { Headline } from 'components/molecules';

class EntryContent extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  render() {
    const { data } = this.props;
    return (
      <>
        {data && <Headline data={data} />}
        <div className="entry-content">
          <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
        </div>
      </>
    );
  }
}

export default EntryContent;
