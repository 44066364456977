import { React, Component, PropTypes } from 'libraries';
import { Tab, TabNav, TabContent, TabContainer } from 'components/molecules';
import PopularPost from '../PopularPost';

class TabWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: [{ title: 'Populer' }],
      active: 0
    };
  }

  getActive = e => {
    this.setState({
      active: e
    });
  };

  render() {
    const { nav, active } = this.state;
    return (
      <div className="widget widget_recent_posts">
        <Tab>
          <TabNav onChange={this.getActive} data={nav} />
          <TabContainer>
            <TabContent active={active === 0}>
              <PopularPost />
            </TabContent>
          </TabContainer>
        </Tab>
      </div>
    );
  }
}

export default TabWidget;
