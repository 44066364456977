import { React, PropTypes, _ } from 'libraries';

const menu = [
  { title: 'Twitter', link: 'https://twitter.com/', className: 'twitter' },
  { title: 'Facebook', link: 'https://facebook.com/', className: 'facebook' },
  { title: 'LinkedIn', link: 'https://linkedin.com/', className: 'linkedin' },
  { title: 'Pinterest', link: 'https://pinteres.com/', className: 'pinterest' }
];

const SocialNav = ({ data }) => (
  <ul className="social-icons">
    {data && !_.isEmpty(data)
      ? data.map((nav, i) => (
          <li key={i} className={nav.type}>
            <a href={nav.url} target="_blank" rel="noopener noreferrer">
              {nav.name}
            </a>
          </li>
        ))
      : null}
  </ul>
);

SocialNav.propTypes = {
  data: PropTypes.array
};

export default SocialNav;
