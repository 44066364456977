import actionTypes from './const';

const initialState = {
  show: false
};

const loader = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADER:
      return {
        ...state,
        show: action.value
      };
    default:
      return state;
  }
};

export default loader;
