import { React, PropTypes } from 'libraries';

const BannerItems = ({ data }) => (
  <li>
    <a className="icon-megaphone style-2" href={data.link}>
      <span>{data.title}</span>
      <i>{data.TitleOverlay}</i>
    </a>
  </li>
);

BannerItems.propTypes = {
  data: PropTypes.object
};
BannerItems.defaultProps = {
  data: {
    title: 'Event',
    TitleOverlay: 'Events',
    link: '#'
  }
};
export default BannerItems;
