import { React, PropTypes, _, Link } from 'libraries';
import { config } from 'config/api/url';

const CardPostFeature = ({
  data,
  url,
  showSnippet,
  smallTitle,
  style,
  className
}) => {
  const image =
    data.thumbnail && data.thumbnail.indexOf('http') !== -1
      ? data.thumbnail
      : `${config.url.origin}${data.thumbnail}`;
  return (
    <div className={`m-card-post-feature__wrapper ${className} `} style={style}>
      <div className="m-card-post-feature__content">
        <div
          className="m-card-post-feature__thumbnail"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        <ul className="m-card-post-feature__category-wrapper">
          {!_.isEmpty(data.categories) && _.isArray(data.categories) ? (
            data.categories.map((tag, index) => (
              <li key={index}>
                <Link
                  rel="category tag"
                  to={`/search?keyword=${tag.slug}`}
                  className="m-card-post-feature__category-item"
                  title={tag.name}
                >
                  {tag.name}
                </Link>
              </li>
            ))
          ) : (
            <li>
              <span
                rel="category tag"
                className="m-card-post-feature__category-item--uncategories"
                title="Uncategories"
              >
                Tanpa kategori
              </span>
            </li>
          )}
        </ul>
        <div className="card-body m-card-post-feature__body">
          <h2
            className={`m-card-post-feature__title m-card-post-feature__title${
              smallTitle ? '--small' : ''
            }`}
          >
            <Link to={url}>{`${data.title.substr(0, 70)}`}</Link>
          </h2>
          {showSnippet && (
            <p className="m-card-post-feature__snippet">{data.snippet}</p>
          )}
        </div>
      </div>
    </div>
  );
};

CardPostFeature.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string,
  showSnippet: PropTypes.bool,
  smallTitle: PropTypes.bool,
  style: PropTypes.any,
  className: PropTypes.string
};

CardPostFeature.defaultProps = {
  showSnippet: true,
  smallTitle: false,
  className: ''
};
export default CardPostFeature;
