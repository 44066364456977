import { React, PropTypes } from 'libraries';

const CategoryItem = ({ data }) => (
  <li className="cat-item">
    <a href={data.url}>{data.title}</a>
  </li>
);

CategoryItem.propTypes = {
  data: PropTypes.object
};

CategoryItem.defaultProps = {
  data: {
    title: 'News',
    url: '#'
  }
};

export default CategoryItem;
