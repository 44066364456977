const actionTypes = {
  SET_SOCIAL_LINK_FACEBOOK: 'SET_SOCIAL_LINK_FACEBOOK',
  SET_SOCIAL_LINK_INSTAGRAM: 'SET_SOCIAL_LINK_INSTAGRAM',
  SET_SOCIAL_LINK_TWITTER: 'SET_SOCIAL_LINK_TWITTER',
  SET_SOCIAL_LINK_WHATSAPP: 'SET_SOCIAL_LINK_WHATSAPP',
  SET_SOCIAL_LINK_YOUTUBE: 'SET_SOCIAL_LINK_YOUTUBE',
  SET_SOCIAL_LINK_WEBSITE: 'SET_SOCIAL_LINK_WEBSITE',
  SET_SOCIAL_LINK_DATA: 'SET_SOCIAL_LINK_DATA'
};

export default actionTypes;
