import { React, PropTypes, _, Link, moment } from 'libraries';
import { config } from 'config/api/url';

const PostCard = ({
  data,
  url,
  showSnippet,
  showTitle,
  showThumbnail,
  showFooter,
  wrapperStyle
}) => {
  const image =
    data.thumbnail &&
    _.isString(data.thumbnail) &&
    data.thumbnail.indexOf('http') !== -1
      ? data.thumbnail
      : `${config.url.origin}${data.thumbnail}`;
  return (
    <div style={wrapperStyle} className="post border m-post-card__wrapper">
      {showThumbnail && (
        <div className="entry-media m-post-card__thumbnail">
          <Link className="image-post item-overlay" to={url}>
            <img src={image} alt={data.title} title={data.title} />
          </Link>
        </div>
      )}

      <div className="entry-content m-post-card__entry-content">
        {showTitle && (
          <h3 className="entry-title m-post-card__entry-title">
            <Link to={url} title={data.title}>
              {data.title}
            </Link>
          </h3>
        )}
        {showSnippet && <p>{data.snippet}</p>}
      </div>

      {showFooter && (
        <footer className="entry-footer">
          <div className="left">
            {data.categories &&
              data.categories.map((category, i) => {
                if (i === 0) {
                  return (
                    <span key={i} className="cat-links">
                      <Link
                        to={`/search?keyword=${category.slug}`}
                        rel="category tag"
                        key={i}
                      >
                        {category.name}
                      </Link>
                    </span>
                  );
                }
                return null;
              })}
          </div>

          <div className="right">
            <span className="posted-on">
              <Link to="/">
                {data.created_at &&
                  moment(data.created_at).format('DD MMMM YYYY')}
              </Link>
            </span>
            <Link className="post-like icon-eye" data-post_id={data.id} to="/">
              <span className="vote-count">{data.view}</span>
            </Link>
          </div>
        </footer>
      )}
    </div>
  );
};

PostCard.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string,
  showSnippet: PropTypes.bool,
  showTitle: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  showFooter: PropTypes.bool,
  wrapperStyle: PropTypes.any
};

PostCard.defaultProps = {
  showSnippet: true,
  showTitle: true,
  showThumbnail: true,
  showFooter: true
};

export default PostCard;
