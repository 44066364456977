import { React, PropTypes } from 'libraries';

const BlockIconList = ({ data }) => (
  <li>
    <a href={data.link}>
      <i className={data.Icon}></i>
      <h5>{data.title}</h5>
      <span>{data.desc}</span>
    </a>
  </li>
);

BlockIconList.propTypes = {
  data: PropTypes.object
};

BlockIconList.defaultProps = {
  data: {
    Icon: 'icon-group',
    title: 'Campaign',
    desc: 'Lorem Ipsum Dolor Consectetur',
    link: '##'
  }
};

export default BlockIconList;
