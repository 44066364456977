import { React, PropTypes } from 'libraries';

const Skeleton = ({ className, style }) => (
  <div style={style} className={`m-skeleton__wrapper ${className || ''}`}></div>
);

Skeleton.propTypes = {
  className: PropTypes.any,
  style: PropTypes.any
};

export default Skeleton;
