import { React, PropTypes, NavLink, _ } from 'libraries';
import Website from 'website';

const HeaderNav = ({ menu }) => (
  <div className="menu-primary-menu-container">
    <ul id="menu-primary-menu" className="menu">
      {menu && !_.isEmpty(menu) ? (
        menu.map((nav, i) => (
          <li key={i} className="menu-item">
            <NavLink to={nav.link}>{nav.title}</NavLink>
            {nav.submenu && !_.isEmpty(nav.submenu) ? (
              <ul className="sub-menu">
                {nav.submenu.map((subnav, j) => (
                  <li key={j}>
                    <NavLink to={subnav.link}>{subnav.title}</NavLink>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))
      ) : (
        <li className="menu-item">
          <NavLink activeStyle={{ fontWeight: 'bold' }} to="/">
            Home
          </NavLink>
        </li>
      )}
    </ul>
  </div>
);

HeaderNav.propTypes = {
  menu: PropTypes.any
};

HeaderNav.defaultProps = {
  menu: Website.navigation.menu
};

export default HeaderNav;
