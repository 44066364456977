import { React, PropTypes } from 'libraries';

const FooterBy = ({ text, title, link }) => (
  <div className="developed">
    {text} <a href={link}>{title}</a>
  </div>
);

FooterBy.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string
};

FooterBy.defaultProps = {
  text: 'Develop by',
  title: 'Kabayan Coding',
  link: 'https://www.kabayancoding.com'
};

export default FooterBy;
