import React, { Component, Fragment } from 'react';

class Archive extends Component {
  componentDidMount() {
    document.title = 'LPTQ - Lembaga Pengembangan Tilawatil Quran';
  }

  render() {
    return (
      <Fragment>
        <div id="main" className="medium-8 large-8 columns">
          <div className="post full-width">
            <h2>Archive</h2>
          </div>
          <div className="cp-container"></div>
        </div>
      </Fragment>
    );
  }
}

export default Archive;
