import { React, PropTypes, _ } from 'libraries';
import Website from 'website';

const NavPages = ({ menu }) => (
  <div className="widget widget_nav_menu">
    <h3 className="widget-title">Pintasan</h3>

    <div className="menu-campaign-container">
      <ul id="menu-campaign" className="menu">
        {menu && !_.isEmpty(menu)
          ? menu.map((nav, i) => (
              <li key={i}>
                <a href={nav.link}>{nav.title}</a>
              </li>
            ))
          : null}
      </ul>
    </div>
  </div>
);

NavPages.propTypes = {
  menu: PropTypes.array
};

NavPages.defaultProps = {
  menu: Website.navigation.footerMenu
};
export default NavPages;
