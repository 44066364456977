import { React, Component, PropTypes } from 'libraries';

class TabContent extends Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.any,
    active: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    active: false
  };

  render() {
    const { children, className, style, active } = this.props;
    return (
      <div
        style={{ ...style, display: active ? 'block' : 'none' }}
        className={`tab-content ${className}`}
      >
        {children}
      </div>
    );
  }
}

export default TabContent;
