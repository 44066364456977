import { React, PropTypes } from 'libraries';
import Website from 'website';

const AboutBrand = ({ data }) => (
  <div id="text-2" className="widget widget_text">
    <h3 className="widget-title">Tentang Kami</h3>
    <div className="textwidget">
      <p>{data.about}</p>
    </div>
  </div>
);

AboutBrand.propTypes = {
  data: PropTypes.object
};

AboutBrand.defaultProps = {
  data: {
    about: Website.about
  }
};

export default AboutBrand;
