import React, { Component } from 'react';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDiscover: false,
      select: false
    };
  }

  handleActiveDiscover = () => {
    this.setState({
      activeDiscover: !this.state.activeDiscover
    });
  };

  render() {
    const { activeDiscover } = this.state;
    const { handleActiveDiscover, onItemSelect } = this;
    return (
      <>
        <div className="large-12 columns">
          <div className="page-title">
            <h1>About Us</h1>

            <div className="breadcrumbs">
              <a href="home.html" title="">
                Home
              </a>
              About
            </div>
          </div>
        </div>

        <section id="main" className="medium-8 large-8 columns">
          <h2>Our Mission</h2>
          <p>
            Non anim maecenas illum dolorem curae labore faucibus nihil ex,
            suspendisse praesentium, quos laudantium? Ridiculus exercitationem!
            Dolore curabitur egestas parturient sagittis parturient? Nullam
            aliquam , consectetuer, qui id praesentium, reiciendis adipiscing
            varius. Mollit officia? Varius, nam laborum.
          </p>
          <img
            className="alignleft"
            width="520"
            alt="kjaskdja"
            src="images/blog/01_blog.jpg"
          />

          <p>
            Do pariatur nihil fames quasi enim perferendis eiusmod corrupti
            sociosqu aliqua fuga, viverra. Elit, wisi nostra ex tempor eligendi
            pariatur!
          </p>

          <p>
            Mattis distinctio ac recusandae. Nam potenti sollicitudin aut eos
            accusamus tristique dictumst, maecenas laboris ullamco.
          </p>
          <ul className="social-icons style-fall">
            <li className="facebook">
              <a href="http://www.facebook.com/wpThemeMakers">Facebook</a>
            </li>
            <li className="linkedin">
              <a href="http://linkedin.com/">Linkedin</a>
            </li>
            <li className="rss">
              <a
                href="http://diplomat.webtemplatemasters.com/feed/"
                title="RSS"
              >
                RSS
              </a>
            </li>
            <li className="pinterest">
              <a href="http://pinterest.com/">Pinterest</a>
            </li>
          </ul>
          <div className="divider"></div>
          <div className="clear"></div>
          <div className="divider-1"></div>

          <div className="section">
            <h3>Who We Are</h3>

            <div className="row">
              <div className="medium-6 large-6 columns">
                <img
                  className="alignleft"
                  alt="kjaskdja"
                  src="images/people/05_people.jpg"
                />
                <h4 className="">CHRISTIAN KLOSS</h4>
                <p>
                  Habitant malesuada! Pede scelerisque doloremque vero conubia
                  auctor.
                </p>
              </div>
              <div className="medium-6 large-6 columns">
                <img
                  className="alignleft"
                  alt="kjaskdja"
                  src="images/people/02_people.jpg"
                />
                <h4 className="">KATHY KERRY</h4>
                <p>
                  Laudantium platea sociosqu adipiscing odit eu, doloremque
                  laboriosam.
                </p>
              </div>
              <div className="medium-6 large-6 columns">
                <img
                  className="alignleft"
                  alt="kjaskdja"
                  src="images/people/01_seminars.jpg"
                />
                <h4 className="">JASON JOE</h4>
                <p>
                  Deleniti risus auctor quia, asperiores, dolores excepturi
                  metus sollicitudin.
                </p>
              </div>
              <div className="medium-6 large-6 columns">
                <img
                  className="alignleft"
                  alt="kjaskdja"
                  src="images/people/04_seminars.jpg"
                />
                <h4 className="">SAM WOLKER</h4>
                <p>
                  Habitant malesuada! Pede scelerisque doloremque vero conubia
                  auctor.
                </p>
              </div>
              <div className="medium-6 large-5 columns">
                <img
                  className="alignleft"
                  alt="kjaskdja"
                  src="images/people/04_people.jpg"
                />
                <h4 className="">EMILY GREEN</h4>
                <p>
                  Laudantium platea sociosqu adipiscing odit eu, doloremque
                  laboriosam.
                </p>
                <div className="divider"></div>
              </div>
              <div className="medium-6 large-6 columns">
                <img
                  className="alignleft"
                  alt="kjaskdja"
                  src="images/people/02_seminars.jpg"
                />
                <h4 className="">MARKO SATO</h4>
                <p>
                  Deleniti risus auctor quia, asperiores, dolores excepturi
                  metus sollicitudin.
                </p>
              </div>
            </div>
            <div className="divider-1"></div>
          </div>

          <div className="section padding-off">
            <div className="relative">
              <h3 className="">Become a Volunteer</h3>
              Laboris iaculis potenti aliquam sociosqu a? Recusandae facilisis
              lorem
              <span className="highlight tooltip">
                <b>Possimus ridiculus, eleifend, quod asperiores aliqua.</b>
                laboriosam eligendi
              </span>{' '}
              varius quasi eligendi? Cumque ex auctor, aperiam hymenaeos cum,
              aute iusto mollitia netus proin morbi, pariatur! Nonummy praesent
              arcu.
              <a href="volunteer.html">
                <strong>Apply the form.</strong>
              </a>
              <div className="clear"></div>
              <div className="divider-1"></div>
            </div>
          </div>

          <div className="section padding-off">
            <div className="row">
              <div className="medium-6 large-6 columns">
                <h3 className="">Job Opportunities</h3>
                <ul className="list">
                  <li className="icon-edit">
                    Administrators specialising in legal research
                  </li>
                  <li className="icon-edit">Administrators - Generalists</li>
                  <li className="icon-edit">Auditors</li>
                  <li className="icon-edit">Delegations</li>
                  <li className="icon-edit">Parliamentary Ushers</li>
                  <li className="icon-edit">
                    Cooperation and Management of aid
                  </li>
                </ul>
              </div>

              <div className="medium-6 large-6 columns">
                <h3 className="">Discover</h3>
                <ul className="accordion">
                  <li className="accordion-navigation">
                    <a
                      href="##"
                      onClick={() => handleActiveDiscover(onItemSelect)}
                      className={`acc-trigger ${
                        activeDiscover ? 'active' : ''
                      }`}
                      data-mode=""
                    >
                      Vero officia nesciunt per massa sint risus{' '}
                    </a>
                    <div
                      className="content"
                      style={
                        activeDiscover
                          ? { display: 'block', transition: 'all 0.5s' }
                          : { display: 'none', transition: 'all 0.5s' }
                      }
                    >
                      Vero officia nesciunt per massa sint risus assumenda sed
                      venenatis aperiam blandit, totam? Ratione minim deserunt
                      blandit harum phasellus, dictum! Aliquet accusamus
                      officiis! Eum rem ac interdum mi odit congue.
                    </div>
                  </li>
                  <li className="accordion-navigation">
                    <a
                      href="##"
                      onClick={() => handleActiveDiscover(onItemSelect)}
                      className={`acc-trigger ${
                        activeDiscover ? 'active' : ''
                      }`}
                      data-mode=""
                    >
                      Vero officia nesciunt per massa sint risus{' '}
                    </a>
                    <div
                      className="content"
                      style={
                        activeDiscover
                          ? { display: 'block', transition: 'all 0.5s' }
                          : { display: 'none', transition: 'all 0.5s' }
                      }
                    >
                      Vero officia nesciunt per massa sint risus assumenda sed
                      venenatis aperiam blandit, totam? Ratione minim deserunt
                      blandit harum phasellus, dictum! Aliquet accusamus
                      officiis! Eum rem ac interdum mi odit congue.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!--/ .row--> */}
          </div>
        </section>
      </>
    );
  }
}

export default About;
