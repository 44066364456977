import { React, PropTypes } from 'libraries';

const Breadcrumbs = ({ data }) => (
  <div className="large-12 columns">
    <div className="page-title">
      <h1 title={data.pageTitle}>{data.pageTitle}</h1>
      <div className="breadcrumbs">
        <span>
          <a href="/" title="Lembaga Pengembangan Tilawatil Qur'an">
            Home
          </a>
          <a href="##" title={`View all posts in ${data.Categories}`}>
            {data.Categories}
          </a>
          <span title={data.pageTitle}>{data.pageTitle}</span>
        </span>
      </div>
    </div>
  </div>
);

Breadcrumbs.propTypes = {
  data: PropTypes.object
};

Breadcrumbs.defaultProps = {
  data: {
    Categories: 'uncategories',
    pageTitle:
      'Cek Kesiapan MTQ XVII, Tim Asistensi LPTQ Provinsi Papua Gelar Kunjungan Ke Nabire Congue iure curabitur incididunt consequat'
  }
};
export default Breadcrumbs;
