import { React, BrowserRouter, Switch, Route, _ } from 'libraries';
import routes from './routes';

const App = () => (
  <BrowserRouter>
    <Switch>
      {routes && !_.isEmpty(routes)
        ? routes.map((route, i) => {
            const RouteComponent = route.component;
            const routeProps = {
              title: route.title,
              path: route.path
            };
            return (
              <Route
                key={i}
                exact
                path={route.path}
                component={props => (
                  <RouteComponent {...routeProps} {...props} />
                )}
              />
            );
          })
        : null}
    </Switch>
  </BrowserRouter>
);

export default App;
