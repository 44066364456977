import { React, PropTypes, Component } from 'libraries';
import { CategoryList, WidgetVideo } from 'components/molecules';
import { connect } from 'react-redux';
import FeaturedEvents from 'components/molecules/FeaturedEvents';
import TabWidget from '../TabWidget';

class BaseSidebar extends Component {
  render() {
    const { youtube, match } = this.props;
    return (
      <div id="sidebar" className=" columns small-12 medium-4 large-4">
        <div className="sticky">
          <WidgetVideo source={youtube} />
          <CategoryList />
          {match.path !== '/' && <FeaturedEvents />}
          <TabWidget />
        </div>
      </div>
    );
  }
}

BaseSidebar.propTypes = {
  youtube: PropTypes.string,
  match: PropTypes.object
};

const reduxState = state => ({
  youtube: state.widgetvideo.youtubeEmbedUrl
});

export default connect(
  reduxState,
  null
)(BaseSidebar);
