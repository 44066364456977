import { React, Component, PropTypes, _, moment } from 'libraries';
import { BaseLayout } from 'layout';
import { TableFile, SearchField } from 'components';
import { API } from 'config';

class Document extends Component {
  state = {
    document: [],
    filterSearch: ''
  };

  componentDidMount() {
    document.title = "Dokumen - Lembaga Pengembangan Tilawatil Qur'an";
    this.getDocuments();
  }

  handleOnChange = e => {
    const { filterSearch } = this.state;
    this.setState({
      filterSearch: e.target.value
    });
  };

  handleRefreshButton = () => {
    this.getDocuments();
  };

  getDocuments = () => {
    const { document } = this.state;
    API.getDocuments().then(res => {
      console.log(res);
      this.setState({
        document: res.data
      });
    });
  };

  render() {
    const { document, filterSearch } = this.state;

    // Advance Search
    const advanceSearch = document.filter(
      data =>
        data.name.toLowerCase().indexOf(filterSearch.toLowerCase()) !== -1 ||
        data.type.toLowerCase().indexOf(filterSearch.toLowerCase()) !== -1 ||
        moment(data.created_at)
          .format('HH:mm, DD MMMM YYYY')
          .toLowerCase()
          .indexOf(filterSearch.toLowerCase()) !== -1
    );
    return (
      <BaseLayout showSidebar={false} {...this.props}>
        <section id="main">
          <div className="section padding-off columns medium-12 large-12 background-color-off">
            <div className="row">
              <h2 className="section-title">Download Dokumen</h2>
              <div className="columns small-12 medium-12 large-12 relative">
                <div className="row row-flex">
                  <button
                    onClick={this.handleRefreshButton}
                    className="button"
                    type="button"
                    style={{ margin: 0 }}
                  >
                    Refresh
                  </button>
                  <SearchField
                    value={filterSearch}
                    onChange={this.handleOnChange}
                  />
                  <table>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>No</th>
                        <th>Nama</th>
                        <th>Jenis File</th>
                        <th>Tanggal Upload</th>
                        <th>File</th>
                      </tr>
                    </thead>
                    {advanceSearch.map((documents, i) => (
                      <TableFile data={documents} key={i} index={i} />
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default Document;
