import { React, PropTypes } from 'libraries';

const RecentPosts = ({ data }) => (
  <div className="widget widget_recent_entries">
    <h3 className="widget-title">Fresh From The Blog</h3>
    <ul>
      <li>
        <a href="single-post">Congue iure curabitur incididunt consequat</a>
        <span className="post-date">March 22, 2015</span>
      </li>
      <li>
        <a href="single-post">Around the world on solar power</a>
        <span className="post-date">March 22, 2015</span>
      </li>
      <li>
        <a href="single-post">Ornare, netus, repellendus, pretium fermentum</a>
        <span className="post-date">March 22, 2015</span>
      </li>
    </ul>
  </div>
);

RecentPosts.propTypes = {
  data: PropTypes.object
};

RecentPosts.defaultProps = {
  data: {}
};
export default RecentPosts;
