/**
 * All about setting website
 */

const Website = {
  title: "Lembaga Pengembangan Tilawatil Qur'an",
  description: 'Kantor Provinsi Papua',
  navigation: {
    menu: [
      { title: 'Home', link: '/' },
      {
        title: 'Info Umum',
        link: '#',
        submenu: [
          { title: 'Berita', link: '/search?keyword=berita' },
          { title: 'Informasi', link: '/search?keyword=informasi' },
          { title: 'Keagamaan', link: '/search?keyword=keagamaan' }
        ]
      },
      { title: 'Galeri', link: '/galeri' },
      {
        title: 'Profil',
        link: '#',
        submenu: [
          { title: 'Kontak', link: '/kontak' },
          { title: 'Tentang LPTQ', link: '/tentang' },
          { title: 'Kebijakan Privasi', link: '/kebijakan-privasi' },
          { title: 'FAQ', link: '/faq' }
        ]
      },
      { title: 'Dokumen', link: '/document' }
    ],
    footerMenu: [
      { title: 'Tentang Kami', link: '/tentang' },
      { title: 'FAQ', link: '/faq' },
      { title: 'Kontak', link: '/kontak' }
    ]
  },
  about: "Lembaga Pengembangan Tilawatil Qur'an - Kantor provinsi papua.",
  address: 'Jl. Perkutut, Vim, Abepura, Kota Jayapura, Papua 99225',
  email: 'info@lptqpapua.org',
  phone: '(0651) 7551313 - (0651) 7551312, (0651) 7551314',
  socialShare: [
    {
      title: 'Facebook',
      className: 'facebook',
      url: 'https://www.facebook.com/sharer/sharer.php?u='
    },
    {
      title: 'Twitter',
      className: 'twitter',
      url: 'http://twitter.com/'
    }
  ],
  categoryWidget: [
    { title: 'Berita', url: '/search?keyword=berita' },
    { title: 'Informasi', url: '/search?keyword=informasi' },
    { title: 'Lomba', url: '/search?keyword=lomba' },
    { title: 'MTQ', url: '/search?keyword=mtq' },
    { title: 'FAQ', url: '/faq' }
  ]
};

export default Website;
