import {
  Home,
  SinglePost,
  Archive,
  About,
  Galery,
  Search,
  Document,
  Halaman
} from 'containers';

const routes = [
  { path: '/', title: 'Beranda - Website Lptq Papua', component: Home },
  { path: '/galeri', title: 'Halaman Galeri', component: Galery },
  { path: '/search', component: Search },
  { path: '/document', component: Document },
  { path: '/:halaman', component: Halaman },
  {
    path: '/:type/:slug',
    title: 'Postingan',
    component: SinglePost
  },
  { path: '/tentang', component: About }
];

export default routes;
