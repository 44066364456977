import { React, PropTypes } from 'libraries';
import { connect } from 'react-redux';

const LargeBanner = ({ data }) => (
  <div className="banner-container">
    <div className="banner">
      <img alt="banner-lptq" src={data.images} />
    </div>
  </div>
);

LargeBanner.propTypes = {
  data: PropTypes.object
};

LargeBanner.defaultProps = {
  data: {
    alt: 'Banner',
    images:
      'https://papua.kemenag.go.id/files/gallery/4a4149d9-b39a-4277-b6cd-5de168f27090.jpg'
  }
};

export default LargeBanner;
