import axios from 'axios';
import { Tools } from 'utils';

export const apiInstance = axios.create({
  baseURL: '',
  timeout: 10000,
  validateStatus: status => status >= 200 && status < 300
});

class ApiRequest {
  static get(route, token = false) {
    return payload => this.request('GET', route, payload, token);
  }

  static put(route, token = false) {
    return payload => this.request('PUT', route, payload, token);
  }

  static post(route, token = false) {
    return payload => this.request('POST', route, payload, token);
  }

  static delete(route, token = false) {
    return payload => this.request('DELETE', route, payload, token);
  }

  static patch(route, token = false) {
    return payload => this.request('PATCH', route, payload, token);
  }

  static resolveParams(params) {
    const paramsResult = [];
    Object.keys(params).map(e => paramsResult.push(`${e}=${params[e]}`));
    return paramsResult.join('&');
  }

  static request(method, route, payload = {}, token) {
    return new Promise((resolve, reject) => {
      const path = payload.path ? `/${payload.path}` : '';
      const params = payload.params
        ? `?${this.resolveParams(payload.params)}`
        : '';
      const customUrl = payload.url ? payload.url : '';
      const baseHeaders = {
        'Content-Type':
          payload.type === 'form-data'
            ? 'multipart/form-data'
            : 'application/json'
      };
      if (token) {
        const userToken = Tools.getStorage('api_token');
        baseHeaders.Authorization = `bearer ${userToken || ''}`;
      }

      apiInstance
        .request({
          url: customUrl.length > 0 ? customUrl : route + path + params,
          method,
          headers: payload.headers
            ? { ...baseHeaders, ...payload.headers }
            : baseHeaders,
          data: payload.body ? payload.body : {}
          // cancelToken:  payload.cancelToken ? payload.cancelToken : '';
        })
        .then(res => {
          if (res && res.data) {
            resolve(res.data);
          } else {
            reject(
              new Error({
                status: false,
                message: 'Koneksi/Server Bermasalah'
              })
            );
          }
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            reject(err.response.data);
          } else if (err && err.response) {
            reject(err.response);
          } else if (err) {
            reject(err);
          }
        });
    });
  }
}

export default ApiRequest;
