import { React, PropTypes, Link } from 'libraries';

const NavButtonClose = ({ onClick }) => (
  <Link onClick={onClick} id="advanced-menu-hide" to="#">
    {null}
  </Link>
);

const NavButtonOpen = ({ onClick }) => (
  <Link
    onClick={onClick}
    id="responsive-nav-button"
    className="responsive-nav-button"
    to="#"
  >
    {null}
  </Link>
);

NavButtonClose.propTypes = {
  onClick: PropTypes.func
};

NavButtonOpen.propTypes = {
  onClick: PropTypes.func
};

export { NavButtonClose, NavButtonOpen };
