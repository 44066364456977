import actionTypes from './const';

const initialState = {
  header: '',
  sidebar: '',
  footer: ''
};

const banner = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BANNER_HEADER:
      return {
        ...state,
        header: action.value
      };
    case actionTypes.SET_BANNER_SIDEBAR:
      return {
        ...state,
        sidebar: action.value
      };
    case actionTypes.SET_BANNER_FOOTER:
      return {
        ...state,
        footer: action.value
      };
    default:
      return state;
  }
};

export default banner;
