import { React, PropTypes, Component } from 'libraries';
import { CategoryItem } from 'components/atoms';
import Website from 'website';

class CategoryList extends Component {
  render() {
    return (
      <div id="categories-2" className="widget widget_categories">
        <h3 className="widget-title">Categories</h3>
        <ul>
          {Website.categoryWidget.map((data, i) => (
            <CategoryItem data={data} key={i} />
          ))}
        </ul>
      </div>
    );
  }
}

export default CategoryList;
