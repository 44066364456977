import { config } from 'config/api/url';
import { _ } from 'libraries';

export const typeOf = data => {
  if (typeof data === 'undefined') {
    return 'undefined';
  }
  if (data === null) {
    return null;
  }
  if (data.constructor === [].constructor) {
    return 'array';
  }
  if (data.constructor === {}.constructor) {
    return 'object';
  }
  return typeof data;
};

export const isCanParseJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isCanStringifyJson = str => {
  try {
    JSON.stringify(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const setStorage = (key, data) => {
  if (isCanStringifyJson(data) && typeof data !== 'string') {
    localStorage.setItem(key, JSON.stringify(data));
    return true;
  }
  localStorage.setItem(key, data);
};

export const getStorage = key => {
  const storage = localStorage.getItem(key);
  if (isCanParseJson(storage)) {
    return JSON.parse(storage);
  }
  return storage;
};

export const isLogin = key => {
  const userData = getStorage('userData');
  if (userData && userData.user) {
    return true;
  }
  return false;
};

export const getLastArray = arr => {
  if (!typeOf(arr) === 'array') {
    throw new Error('require data is array');
  }
  if (arr && arr.length) {
    return arr[arr.length - 1];
  }
  return arr;
};

export const getParamsToObject = (getParams = 'default=0') => {
  if (getParams.indexOf('=') > -1) {
    return JSON.parse(
      `{"${decodeURI(getParams)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  }
  return {
    default: '0'
  };
};

export const objectToParams = data => {
  let param = '';
  if (typeOf(data) === 'undefined') {
    return param;
  }
  if (typeOf(data) !== 'object') {
    throw new Error('data must be object');
  }
  for (const key in data) {
    param += param === '' ? '?' : '&';
    param += `${key}=${encodeURIComponent(data[key])}`;
  }
  return param;
};

export const getImage = data => {
  if (data && data.thumbnail && _.isString(data.thumbnail)) {
    if (data.thumbnail.indexOf('http') !== -1) {
      return data.thumbnail;
    }
    return `${config.url.origin}${data.thumbnail}`;
  }
};

export const postUrl = (type, url) => `/${type}/${url}`;
