import { React, PropTypes } from 'libraries';

const SearchField = ({
  placeholder,
  value,
  onChange,
  wrapperClass,
  inputClass
}) => (
  <div className={`search-table m-search-field__wrapper ${wrapperClass}`}>
    <input
      className={`m-search-field__search-input ${inputClass}`}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </div>
);

SearchField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  wrapperClass: PropTypes.string,
  inputClass: PropTypes.string
};

SearchField.defaultProps = {
  placeholder: 'Search here...',
  onChange: () => null,
  wrapperClass: '',
  inputClass: ''
};

export default SearchField;
