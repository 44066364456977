import { React, Component, PropTypes, _ } from 'libraries';
import { GaleryPostCard, Skeleton } from 'components';
import { API } from 'config';
import { BaseLayout } from 'layout';
import { get } from 'http';

class Galery extends Component {
  state = {
    gallery: [],
    initLoading: false
  };

  componentDidMount() {
    document.title = 'Galery - Lembaga Pengembangan Tilawatil Quran';
    this.getGalery();
  }

  getGalery = async () => {
    const { gallery } = this.state;
    try {
      await API.getGalery().then(res => {
        console.log(res);
        this.setState({
          gallery: res.data
        });
      });
    } catch (err) {
      console.log(`error`, err);
    }
  };

  render() {
    const { gallery, initLoading } = this.state;
    return (
      <BaseLayout showSidebar={false} {...this.props}>
        <section id="main">
          <div className="section padding-off columns medium-12 large-12 background-color-off">
            <div className="row">
              <div className="relative">
                <h2 className="section-title">Galeri</h2>
                <div className="row row-flex">
                  {!_.isEmpty(gallery) && _.isArray(gallery)
                    ? gallery.map((galeri, i) => (
                        <GaleryPostCard data={galeri} key={i} />
                      ))
                    : null}
                  {initLoading
                    ? [1, 2, 3].map(val => (
                        <article className="columns medium-6 large-4" key={val}>
                          <Skeleton
                            style={{
                              paddingBottom: '62.25%',
                              width: '100%',
                              marginBottom: 14
                            }}
                          />
                          <Skeleton
                            style={{
                              paddingBottom: '62.25%',
                              width: '100%',
                              marginBottom: 14
                            }}
                          />
                          <Skeleton
                            style={{
                              paddingBottom: '62.25%',
                              width: '100%',
                              marginBottom: 14
                            }}
                          />
                        </article>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default Galery;
