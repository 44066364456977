const development = {
  url: {
    // api: `http://localhost/lptq_backend/public/api`,
    // assets: 'http://localhost/lptq_backend/public/',
    // origin: 'http://localhost/lptq_backend/public/'
    api: `http://localhost:8888/api`,
    asset: 'http://localhost:8888/assets',
    origin: 'http://localhost:8888/'
  }
};

const production = {
  url: {
    api: `http://lptqapi.kabayancoding.com/api`,
    asset: 'http://lptqapi.kabayancoding.com/assets',
    origin: 'http://lptqapi.kabayancoding.com/'
  }
};

export const config = production;

const baseUrl = {
  post: `${config.url.api}/portal/post`,
  featurePost: `${config.url.api}/portal/feature_post`,
  relatedPost: `${config.url.api}/portal/related_post`,
  popularPost: `${config.url.api}/portal/popular_post`,
  updatedPost: `${config.url.api}/portal/updated_post`,
  searchPost: `${config.url.api}/portal/search`,
  banner: `${config.url.api}/portal/banner`,
  widgetVideo: `${config.url.api}/portal/widget_video`,
  socialLink: `${config.url.api}/portal/social_link`,
  document: `${config.url.api}/portal/document`,
  download: `${config.url.api}/portal/download`,
  gallery: `${config.url.api}/portal/gallery`,
  halaman: `${config.url.api}/portal/halaman`
};

export default baseUrl;
