import actionTypes from './const';

export const setSocialFacebook = value => ({
  type: actionTypes.SET_SOCIAL_LINK_FACEBOOK,
  value
});

export const setSocialTwitter = value => ({
  type: actionTypes.SET_SOCIAL_LINK_TWITTER,
  value
});

export const setSocialInstagram = value => ({
  type: actionTypes.SET_SOCIAL_LINK_INSTAGRAM,
  value
});

export const setSocialYoutube = value => ({
  type: actionTypes.SET_SOCIAL_LINK_YOUTUBE,
  value
});

export const setSocialWhatsapp = value => ({
  type: actionTypes.SET_SOCIAL_LINK_WHATSAPP,
  value
});

export const setSocialWebsite = value => ({
  type: actionTypes.SET_SOCIAL_LINK_WEBSITE,
  value
});

export const setSocialData = value => ({
  type: actionTypes.SET_SOCIAL_LINK_DATA,
  value
});
