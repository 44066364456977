/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { React, Component, PropTypes, _ } from 'libraries';

class TabNav extends Component {
  static propTypes = {
    data: PropTypes.array,
    activeTab: PropTypes.any,
    onChange: PropTypes.func
  };

  static defaultProps = {
    activeTab: 0,
    onChange: e => e
  };

  constructor(props) {
    super(props);
    this.state = {
      active: props.activeTab || 0
    };
  }

  onClick = e => {
    const { onChange } = this.props;
    this.setState(
      {
        active: e
      },
      () => {
        if (onChange && _.isFunction(onChange)) {
          onChange(e);
        }
      }
    );
  };

  render() {
    const { active } = this.state;
    const { data } = this.props;
    return (
      <ul className="tabs-nav">
        {data && !_.isEmpty(data) && _.isArray(data)
          ? data.map((nav, i) => (
              <li
                onClick={() => this.onClick(i)}
                className={`${active === i ? 'active' : ''}`}
                key={i}
              >
                <h3>{nav.title}</h3>
              </li>
            ))
          : null}
      </ul>
    );
  }
}

export default TabNav;
