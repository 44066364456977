import { React, PropTypes, _ } from 'libraries';

const WidgetVideo = ({ source }) => {
  if (source && _.isString(source)) {
    return (
      <div className="m-widget-video__wrapper">
        <iframe
          title="youtubevideo"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          width={560}
          height={315}
          frameBorder="0"
          allowFullScreen
          src={source}
          className="m-widget-video__iframe"
        ></iframe>
      </div>
    );
  }
  return null;
};

WidgetVideo.propTypes = {
  source: PropTypes.string
};
WidgetVideo.defaultProps = {
  source: ''
};
export default WidgetVideo;
