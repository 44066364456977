import { React, Link, PropTypes, moment } from 'libraries';
import { Tools } from 'utils';

const CardRecentPost = ({ data, url }) => (
  <div className="recent-post m-card-recent-post__wrapper">
    <div className="post-media  m-card-recent-post__media">
      <Link to={url} className="item-overlay  m-card-recent-post__overlay">
        <img
          className=" m-card-recent-post__image"
          alt={data.title}
          title={data.title}
          src={Tools.getImage(data)}
        />
      </Link>
    </div>

    <div className="post-holder  m-card-recent-post__holder">
      <h4 className="post-title  m-card-recent-post__title">
        <Link to={url} title={data.title}>
          {data.title}
        </Link>
      </h4>

      <div className="entry-meta  m-card-recent-post__meta">
        <span className="posted-on">
          <Link to={url} title={data.title}>
            {data.created_at && moment(data.created_at).format('DD MMMM YYYY')}
          </Link>
        </span>
        <span className="icon-eye">
          <Link to={url} title={data.title}>
            {data.view}
          </Link>
        </span>
      </div>
    </div>
  </div>
);

CardRecentPost.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string
};

export default CardRecentPost;
